import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import HomePageHeroTitle from '../components/HomePage/HomePageHeroTitle';
import pagesJsonLD from '../constants/pagesJsonLD';
import './CaseStudies.css';
import './Overview.css';

const WebinarPage = () => {
  const [submitted, setSubmitted] = useState(false);
  const formId = 'rlv-banner-308d9b61-bd97-445f-88ac-d72030ee3def';
  const buttonClass = 'releva-send-subscription';

  useEffect(() => {
    const handleButtonClick = () => {
      console.log('🔘 Submit button clicked!');
      setSubmitted(true);

      // Simulate a delay for form submission processing
      setTimeout(() => {
        const webinarForm = document.querySelector('.webinarForm');
        if (webinarForm) {
          webinarForm.reset(); // Properly resets the form instead of clearing the inner HTML
          console.log('🗑️ Cleared .webinarForm');
        }

        // Remove Google Calendar button
        const calendarButton = document.querySelector('a[target="_blank"][href*="calendar.google.com"]');
        if (calendarButton) {
          calendarButton.remove();
          console.log('🗑️ Removed Google Calendar button');
        }
      }, 1000); // Adjust the delay as needed
    };

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          const form = document.getElementById(formId);
          const button = document.querySelector(`.${buttonClass}`);

          if (form && !form.dataset.formDetected) {
            console.log('🎉 Form appeared in the DOM!');
            form.dataset.formDetected = 'true';
          }

          if (button && !button.dataset.listenerAdded) {
            button.addEventListener('click', handleButtonClick);
            button.dataset.listenerAdded = 'true';
          }
        }
      });
    });

    const targetNode = document.body;
    observer.observe(targetNode, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);
  return (
    <div className="overview solutions webinar">
      <Helmet>
        <title>Releva AI | Webinar</title>
        <meta
          name="description"
          content="Explore Releva AI's comprehensive overview. Learn how our AI-driven marketing platform can transform your business with personalized customer experiences, data-driven insights, and automated campaigns. Discover the future of marketing with Releva AI."
        />
        <script type="application/ld+json">{JSON.stringify(pagesJsonLD.solutionsPets)}</script>
      </Helmet>
      <div className="solutionsHero">
        <HomePageHeroTitle
          heroTitle="The 35% Revenue Advantage: Why Autonomous 1:1 Personalization Is Outperforming Traditional Digital Marketing"
          heroDescription="<p><b>Discover the breakthrough in AI-powered personalization that's delivering unprecedented revenue growth across industries.</b></p>
<p>In this exclusive webinar, Releva reveals how autonomous 1:1 personalization is fundamentally transforming digital marketing results. While traditional personalization has promised but failed to deliver true individualization for over two decades, our revolutionary approach is consistently generating 35% revenue growth for businesses of all sizes.</p>
<p><b>You'll learn:</b></p>
<ul>
    
<li>Why traditional segment-based personalization has hit a performance ceiling</li>
<li>How our AI agents create truly individual customer journeys without requiring massive technical resources</li>
<li>The science behind our 1600% increase in engagement metrics and 35% revenue lift</li>
<li>Real-world case studies from companies that have implemented autonomous personalization</li>
<li>A practical framework for evaluating the revenue impact potential for your business</li>
</ul>
<p>This is not just another incremental improvement in digital marketing—it's a paradigm shift in how personalization works. Join us to understand why legacy platforms simply cannot match these results and how you can position your business to capture this significant revenue advantage.</p>
<p><b>Who should attend</b>: Digital marketing leaders, CMOs, e-commerce directors, and growth strategists looking to break through performance plateaus and drive substantial revenue growth.</p>
<p><b>Date</b>: April 10th 2025 (Thursday) at 5:00 pm (CET), 8:00 am Pacific time (PDT), 11:00 pm Eastern Time (EDT)</p>
<p><b>Duration</b>: 45 minutes + 15 minutes Q&A</p>
"
          heroYoutube={false}
          heroLink="false"
        />
        <section className="symbol-library">
          <div className="webinarForm">
            {submitted && (
              <>
                <p
                  style={{
                    lineHeight: '2em',
                    padding: '0 15px',
                  }}
                >
                  Thank you for signing up for our exclusive webinar! Stay ahead by adding the event to your calendar
                  now. Simply click &nbsp;
                  <a
                    target="_blank"
                    href="https://calendar.google.com/calendar/event?action=TEMPLATE&tmeid=NzJiNDAzc2ZybW45Mmw5NHNhcm0wdDgza3QgY185YWExYjUyN2FkNjU3NGQzZjY3N2U4YjkxZWMwZDMzNGI3NDIwMzAxNWE5NjQ0MWU1ZjFjYzQyOTYyYjhjZDcwQGc&tmsrc=c_9aa1b527ad6574d3f677e8b91ec0d334b74203015a96441e5f1cc42962b8cd70%40group.calendar.google.com"
                  >
                    <img
                      border="0"
                      src="https://calendar.google.com/calendar/images/ext/gc_button1_en.gif"
                      alt="Google Calendar"
                    />
                  </a>
                  &nbsp; to ensure you don’t miss out on valuable insights!
                </p>
              </>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default WebinarPage;
